import { Flex, Skeleton } from 'antd';
import styles from './index.module.less';
import { HomeArticleModel } from '@/types/home';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { getPathTitle, imgUrl } from '@/shared/utils';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const HomeRightTopArticleItem = ({ dataList }: { dataList?: HomeArticleModel[] }) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const onClick = (item?: HomeArticleModel) => {
    if (item.article_id != undefined) {
      // router.push(`/article/${item.article_id}`)
      router.push(`/article/${item?.article_id}/${getPathTitle(item?.title)}`);
    }
  };

  function createItem(index: number, item?: HomeArticleModel) {
    return (
      <Flex className={styles.flexItem} key={item?.article_id} onClick={() => onClick(item)}>
        <span className={styles.indexItem}> {index + 1} </span>
        <Image
          className={styles.imgItem}
          src={imgUrl(item?.cover_image, 'small')}
          loading="lazy"
          alt={'article'}
          width={200}
          height={200}
        />
        <Link href={`/article/${item?.article_id}/${getPathTitle(item?.title)}`}>
          <span className={styles.titleItem}>{item?.title}</span>
        </Link>
      </Flex>
    );
  }

  function emptyItem(index: number) {
    return (
      <Flex className={styles.flexItem}>
        <span className={styles.indexItem}> {index + 1} </span>
        <Skeleton.Image active style={{ width: '60px', height: '60px' }} />
        <Skeleton.Input active size={'small'} />
      </Flex>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <h3>{t('topBlogs')}</h3>

      {_.isEmpty(dataList) ? (
        <>
          {emptyItem(0)}
          {emptyItem(1)}
        </>
      ) : (
        <>
          {(dataList ?? []).map((item, index) => {
            return createItem(index, item);
          })}
        </>
      )}
    </div>
  );
};

export default HomeRightTopArticleItem;
