import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
// import { EventSource } from '@/shared/event-types';
// import useBusinessSettingsDisplay from '@/lib/hooks/common/useBusinessSettingsDisplay';
import _ from 'lodash';
import { Affix, Col, Row } from 'antd';
import { getArticleListData } from '@/lib/service/home';
import { BaseBookModel, HomeArticleModel, HomeGPTsModel, HomeThemeModel } from '@/types/home';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import { imgUrl } from '@/shared/utils';
import TopImage from './TopImage';
import HomeFullItem from './HomeFullItem';
import HomeGptsRemmondItem from './HomeGptsRemmondItem';
import HomeRightTopArticleItem from './TopArticles';
import HomeRightTopGPTsItem from './TopGPTs';
import { useTranslation } from 'next-i18next';
import HomeBookRemmondItem from '../Common/HomeBookRemmondItem';

const HomeContent = ({ data }: { data: any }) => {
  const { t } = useTranslation('common');
  const articleListRes: any = data['articleList'];

  const [theme] = useState<HomeThemeModel>(data['theme']);
  const [gptsList] = useState<HomeGPTsModel[]>(data['gptsList']);
  const [gptsHotList] = useState<HomeGPTsModel[]>(data['gptsHotList']);
  const [articleList, setArticleList] = useState<HomeArticleModel[]>(articleListRes?.data);
  const [articleNetList, setArticleNetList] = useState<HomeArticleModel[]>(articleListRes?.data);
  const [articleHotList] = useState<HomeArticleModel[]>(data['articleHotList']);
  const [bookList] = useState<BaseBookModel[]>(data['bookList']);

  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState<number>(articleListRes?.total);
  const [pageNo, setPageNo] = useState<number>(1);
  const page_size = 10;

  // size
  const { width } = useScreenSize();

  const rightMargin = () => {
    if (width >= 768) {
      return '1rem';
    }
    return undefined;
  };

  const topGPTsItems = () => {
    if (width >= 768) {
      return (
        <Affix offsetTop={84}>
          <HomeRightTopGPTsItem dataList={gptsHotList} />
        </Affix>
      );
    } else {
      return <HomeRightTopGPTsItem dataList={gptsHotList} />;
    }
  };

  const fetchMoreData = () => {
    if (loading || noData) {
      return;
    }
    setLoading(true);
    getArticleListData({ page_no: pageNo + 1, page_size: page_size }, data['locale'])
      .then((data) => {
        setPageNo(pageNo + 1);
        setTotalSize(data.total);
        setArticleNetList(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   const articleListRes: any = data["articleList"]
  //   setTotalSize(articleListRes?.total)
  //   setArticleNetList(articleListRes?.data)
  // }, [data]);

  useEffect(() => {
    if (articleNetList?.length == 0) {
      return;
    }

    const tempIdList = articleList.map((item) => {
      return item.article_id;
    });
    const tempList = articleNetList?.filter((item): boolean => {
      return !tempIdList.includes(item.article_id);
    });

    if (tempList?.length > 0) {
      setArticleList([...articleList, ...tempList]);
    }
  }, [articleNetList]);

  useEffect(() => {
    setNoData(totalSize != 0 && articleList.length >= totalSize);
  }, [totalSize, articleList]);

  return (
    <div
      className={styles.homeplace}
      style={{
        backgroundImage: `url(${imgUrl(theme?.background_image, 'big')})`,
      }}
    >
      <div className={styles.homeContent}>
        <TopImage src={theme?.main_image} />
        {/* <HomeGptsItem data={contentData?.gptsrecommend} /> */}

        <Row style={{ width: '100%' }}>
          <Col md={18} xs={24}>
            <div style={{ marginRight: rightMargin() }} className={styles.leftContainer}>
              {_.isEmpty(gptsList) && _.isEmpty(articleList) && (
                <>
                  <HomeFullItem />
                  <HomeFullItem />
                  <HomeFullItem />
                  <HomeFullItem />
                </>
              )}

              {(articleList ?? []).map((item, index) => {
                if (index > 1) {
                  return null;
                }
                return <HomeFullItem key={item.article_id} articleModel={item} />;
              })}

              {!_.isEmpty(gptsList) && (
                <>
                  <HomeGptsRemmondItem data={gptsList} />
                </>
              )}

              {(articleList ?? []).map((item, index) => {
                if (index > 1 && index < 4) {
                  return <HomeFullItem key={item.article_id} articleModel={item} />;
                }
                return null;
              })}


              {process.env.NEXT_APP_TENANTID === '1' && !_.isEmpty(bookList) && (
                <>
                  <HomeBookRemmondItem data={bookList} />
                </>
              )}


              {(articleList ?? []).map((item, index) => {
                if (index < 4) {
                  return null;
                }
                return <HomeFullItem key={item.article_id} articleModel={item} />;
              })}

              {!noData && (
                <div className={styles.loadMoreContent}>
                  <div className={styles.loadMore} onClick={fetchMoreData}>
                    <div className={styles.loadMoreBtn}>
                      {loading ? `${t('loading')}...` : t('loadMore')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={6} xs={24}>
            <HomeRightTopArticleItem dataList={articleHotList} />
            {topGPTsItems()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeContent;
