import styles from './index.module.less';
import { BaseBookModel } from '@/types/home';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import RecommendedBookItem from '../BookListItem';

const HomeGptsRemmondItem = ({ data }: { data?: BaseBookModel[] }) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <div className={styles.backContainer}>
      <div className={styles.topDiv}>
        <div>
          <span style={{ color: `var(--app-text-color)` }}>{t('FeaturedBooks')}</span>
        </div>
        <Link href="/recommended-books">{`${t('more')} >`}</Link>
      </div>

      <div className={styles.mainItem}>
        {(data ?? []).map((item, index) => {
          if (index > 2) {
            return null
          }
          return <RecommendedBookItem key={index} data={item} />
        })}
      </div>
    </div>
  );
};

export default HomeGptsRemmondItem;
