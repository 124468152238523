import { Col, Row, Skeleton } from 'antd';
import styles from './index.module.less';
import { HomeArticleModel } from '@/types/home';
import { useRouter } from 'next/router';
import { getPathTitle, imgUrl } from '@/shared/utils';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

const HomeFullItem = ({
  articleModel,
  showBottomLine = true,
  showPadding = true,
}: {
  articleModel?: HomeArticleModel;
  showBottomLine?: Boolean;
  showPadding?: Boolean;
}) => {
  const router = useRouter();
  const { width } = useScreenSize();

  const titleStyle = () => {
    if (width >= 576) {
      return classNames(styles.titleContainer, styles.pcTitleContainer);
    }
    return classNames(styles.titleContainer, styles.mobileTitleContainer);
  };

  const onClick = () => {
    if (articleModel?.article_id != undefined) {
      // router.push(`/article/${articleModel?.article_id}`)
      router.push(`/article/${articleModel?.article_id}/${getPathTitle(articleModel?.title)}`);
    }
  };

  return (
    <>
      <Row
        className={styles.mainContainer}
        style={{
          borderBottom: showBottomLine ? '1px solid var(--app-line-bg-rgb)' : undefined,
          cursor: 'pointer',
          padding: showPadding ? '24px' : '24px 0',
        }}
        onClick={onClick}
      >
        <Col span={articleModel != null && articleModel?.cover_image === null ? 24 : 14} style={{ paddingRight: '1rem' }}>
          {articleModel == null ? (
            <Skeleton active />
          ) : (
            <>
              <Link
                href={`/article/${articleModel?.article_id}/${getPathTitle(articleModel?.title)}`}
              >
                <div className={titleStyle()}>{articleModel?.title}</div>
              </Link>
              {width >= 768 && <p className={styles.descContainer}>{articleModel?.description}</p>}
              <div className={styles.timeContainer}>{articleModel?.create_time}</div>
            </>
          )}
        </Col>
        <Col span={articleModel != null && articleModel?.cover_image === null ? 0 : 10}>
          {articleModel === null ? (
            <Skeleton.Image active />
          ) : (
            <>
              {articleModel?.cover_image && <Image
                className={styles.imgContainer}
                src={imgUrl(articleModel?.cover_image, 'mid')}
                alt={articleModel.title}
                loading="lazy"
                width={296}
                height={166}
                layout="responsive"
              />}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default HomeFullItem;
