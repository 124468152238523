import { Card, Col, Flex, Row, Typography } from 'antd';
import styles from './index.module.less';
import { HomeGPTsModel } from '@/types/home';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getPathTitle, imgUrl } from '@/shared/utils';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const SubItem = ({ model, onClick }: { model?: HomeGPTsModel; onClick?: () => void }) => {
  return (
    <Col
      lg={8}
      sm={12}
      xs={24}
      style={{ margin: '30px 0 0 0', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Card
        bodyStyle={{
          padding: '50px 25px 25px 25px',
          height: '164px',
          width: '100%',
          background: `var(--foreground-rgb)`,
          borderRadius: '5px',
        }}
      >
        <Image
          className={styles.imgItem}
          src={imgUrl(model?.logo, 'small')}
          alt="GPTs"
          loading="lazy"
          width={200}
          height={200}
        />
        <Link href={`/gpts/${model?.gpts_id}/${getPathTitle(model?.title)}`}>
          <h3 className={styles.titleItem}>{model?.title}</h3>
        </Link>
        <span className={styles.descItem}>{model.description}</span>
      </Card>
    </Col>
  );
};

const HomeGptsRemmondItem = ({ data }: { data?: HomeGPTsModel[] }) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <div className={styles.backContainer}>
      <div className={styles.topDiv}>
        <div>
          <span style={{ color: `var(--app-text-color)` }}>{t('featuredGPTsHighlights')}</span>
        </div>
        <Link href="/gpts">{`${t('more')} >`}</Link>
      </div>

      <Row gutter={24} className={styles.mainItem}>
        {(data ?? []).map((item, index) => {
          return (
            <SubItem
              key={index + item.title}
              model={item}
              onClick={() => {
                if (item.gpts_id != undefined) {
                  // router.push(`/gpts/${item.gpts_id}`)
                  router.push(`/gpts/${item?.gpts_id}/${getPathTitle(item?.title)}`);
                }
              }}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default HomeGptsRemmondItem;
