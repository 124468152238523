import React from 'react';
import styles from './index.module.less';
import { Rate } from 'antd';
import { getPathTitle, imgUrl } from '@/shared/utils';
import Image from 'next/image';
import { BaseBookModel } from '@/types/home';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const RecommendedBookItem = ({ style, data }: { style?: React.CSSProperties, data?: BaseBookModel }) => {
  const { t } = useTranslation()

  return (
    <Link href={`/recommended-books/${data?.id}/${getPathTitle(data?.book_name)}`}>
      <div className={styles.content} style={style}>
        <div className={styles.imgContent}>
          <Image
            src={imgUrl(data?.main_image, 'mid')}
            alt={data?.book_name}
            width={100}
            height={100}
            className={styles.imgItem}
            loading="lazy"
          />
        </div>

        <p className={styles.title}>{data?.book_name}</p>

        <div className={styles.author}>
          <span>{t('Author')}:</span>
          <span>{data?.authors}</span>
        </div>

        <Rate className={styles.rateContainer} disabled allowHalf defaultValue={data?.score} />
      </div>
    </Link>
  );
};

export default RecommendedBookItem;
