import axios from '@/shared/axios';
import { BaseBookModel, BookDetailModel, HomeArticleModel, HomeGPTsModel, HomeThemeModel } from '@/types/home';
import { BaseResponse } from '@/types/web.interface';
import { ISitemapField } from 'next-sitemap';

export async function getThemeData(locale: string) {
  const res = await axios.get<BaseResponse<HomeThemeModel>>('/theme/get', {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

export async function getGptsListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list/hot', {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

export async function getGptsListData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list', {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

export async function getArticleListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>('/article/list/hot', {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

export async function getArticleListData(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>('/article/list', {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// article search list
export async function getArticleListSearchData(
  params: any,
  tagRequest: Boolean = false,
  locale?: string,
) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    tagRequest ? '/article/list/label' : '/article/list/search',
    {
      params,
      headers: {
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

// gpts search list
export async function getGptsListSearchData(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list/search', {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// article detail
export async function getArticleDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(`/article/detail/${id}`, {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// article detail preview
export async function getArticlePreviewDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(`/article/preview/detail/${id}`, {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// gpts detail
export async function getGptsDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeGPTsModel>>(`/gpts/detail/${id}`, {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// article recommend
export async function getArticleRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend`, {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// article recommend
export async function getArticleRecommendTags(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend/tag`, {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// gpts recommend
export async function getGPTsRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>(`/gpts/list/recommend`, {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// category top list
export async function getCategoryTopList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/top`, {
    params,
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}

// category top list
export async function getSitemapList() {
  const res = await axios.get<BaseResponse<[ISitemapField]>>(`/sitemap/list`);
  return res.data;
}


// books list
export async function getBooksListData(params: any) {
  const res = await axios.get<BaseResponse<[BaseBookModel]>>('/book/page', {
    params
  });
  return res.data;
}

// Book detail
export async function getBookDetailData(id: any) {
  const res = await axios.get<BaseResponse<[BookDetailModel]>>(`/book/detail/${id}`);
  return res.data;
}

// books recommend
export async function getBookRecommendListData(params: any) {
  const res = await axios.get<BaseResponse<[BaseBookModel]>>('/book/recommend');
  return res.data;
}

// /article/list/top
export async function getArticleTopList(locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend`, {
    headers: {
      'Accept-Language': locale,
    },
  });
  return res.data;
}